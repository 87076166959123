$(document).ready(function () {
    $.ajaxSetup({
        cache: false,
    });

    if ($.fn.slick) {
        $('.slider-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        dots: true
                    }
                },
            ],
            asNavFor: '.slider-nav'
        });
        $('.slider-nav').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            // arrows: true,
            centerMode: true,
            focusOnSelect: true
        });
    }

    var timeout;
    $('body').on('keyup', '#searchInput', function () {

        // JS for search
        var input = $(this);
        var value = input.val();

        clearTimeout(timeout);

        if (value.length >= 3) {
            timeout = setTimeout(function () {
                $.ajax({
                    url: '/?q=' + value + '&whisperer=1',
                    cache: false,
                }).done(function (response) {
                    $('#search-results').html(response); // pro html
                });
            }, 400);
        }
    });

    $('body').on('click', 'a.page-numbers', function (event) {

        event.preventDefault();
        var link = $(this).attr('href');

        $.ajax({
            url: link,
            cache: false,
        }).done(function (response) {
            $('.ajax-paginator-content').html(response);

            history.pushState({
                content: $('html').html(),
            }, null, link);

            $('html, body').animate({
                scrollTop: $('.main-content').offset().top
            }, 400);

            lazyLoadInstance.update();
        });
    });

    $('body').on('change', '#filterSearch .form-check-input', function () {

        var checked = $('#filterSearch .form-check-input:checked');
        var values = {};

        checked.each(function (index, el) {
            if (!values[$(this).attr('name')]) {
                values[$(this).attr('name')] = [];
            }
            values[$(this).attr('name')].push($(this).val());
        });

        $('#resetFilter').toggleClass('d-none', checked.length === 0);

        var link = $('#filterSearch').data('base-uri') + '?' + $.param(values);

        $.ajax({
            url: link,
            cache: false,
        }).done(function (response) {
            $('.ajax-paginator-content').html(response);

            history.pushState({
                content: $('html').html(),
            }, null, link);

            $('html, body').animate({
                scrollTop: $('.main-content').offset().top
            }, 400);

            lazyLoadInstance.update();
        });
    });

    $('#resetFilter').click(function (event) {
        event.preventDefault();
        event.stopPropagation();
        $('#filterSearch .form-check-input').prop('checked', false).last().trigger('change');
    });

    $('#newsletter').submit(function (event) {
        event.preventDefault();

        $.ajax({
            url: $(this).attr('action'),
            data: $(this).serialize(),
            type: 'POST',
            dataType: 'json'
        }).always(function (response) {
          if (response.status == 'ok') {
            $('.newsletterModal.success').show();
          }
          else {
            $('.newsletterModal.error').show();
          }
        });
    });

    var lastClick;
    $('#dropdownMenu').on('hide.bs.dropdown', function (e) {
        var target = $(e.relatedTarget).parent('li')[0];

        if (lastClick.parent('li')[0] === target) {
            return true;
        }

        if (lastClick && $.contains(target, lastClick[0])) {
            return false;
        }

        return !e.clickEvent;
    });

    $('#dropdownMenu .nav-link.dropdown-toggle').click(function (e) {
        lastClick = $(this);
    });
});
