let buildWidget = function () {
  $('.av-widget').each(function () {
    let item = $(this);

    let content = buildContent(item);


    let temp = `

          <div class="av-wrapper">
            <div class="av-title">
              ${$(this).attr('data-title')}
            </div>
            <div class="av-content">

            </div>
          </div>
          <div class="cookie-bar"></div>

        `

    item.html(temp);
    item.find('.av-content').append(content);

    if (!window.cookieConsent.allowedCategory('third_party') && (!window.cookieConsent.get('data') || !window.cookieConsent.get('data').decline_third_party_cookies)) {
      let cookieBar = $(`<div class="cookie-bar-content">
          <p>Kliknutím na náhled / tlačítko Přehrát Vás přesměrujeme na samotný audio/video obsah na webu třetí strany. Pro přehrávání obsahu přímo na stránkách narodniportal.cz, prosím potvrďte jednorázově souhlas s ukládáním cookies třetích stran.</p>
          <div class="cookies-buttons">
            <a href="#" class="agree accept-third-party-cookies">Souhlasím</a>
            <a href="#" class="decline-third-party-cookies">Znovu se neptat</a>
          </div>
        </div>`);
      item.find('.cookie-bar').append(cookieBar);
    }
  })
}
let buildContent = function (item) {
  let embed = JSON.parse(item.attr('data-embed'));
  let picture = item.attr('data-image');

  if (embed.provider == 'youtube') {
    if (window.cookieConsent.allowedCategory('third_party')) {
      let content = $(`<div class="video"><div class="frame-wrapper">
        <iframe width="560" height="315" src="${embed.src}"
                      title="" frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen></iframe>
                        </div></div>

        `)
      return content;
    } else {
      if (picture) {
        let content = $(`<div class="video"><div class="frame-wrapper placeholder">
          <img src="${picture}">
          <a href="${embed.url}" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM384 256L176 384V128L384 256z"/></svg>
        </a>
        </div>
        </div>
        `)
        return content;
      } else {
        let content = $(`<div class="video"><div class="frame-wrapper placeholder">
          <a href="${embed.url}" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM384 256L176 384V128L384 256z"/></svg>
        </a>
        </div>
        </div>
        `)
        return content;
      }

    }
  } else {
    if (window.cookieConsent.allowedCategory('third_party')) {
      let content = $(`<div class="audio">
        <div class="frame-wrapper"><iframe style="border-radius:12px" src="${embed.src}" width="100%" height="169" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe></div></div>
        `)
      return content;
    } else {
      if (picture) {
        let content = $(`<div class="audio"><div class="frame-wrapper placeholder"><img src="${picture}">
          <a href="${embed.url}" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM384 256L176 384V128L384 256z"/></svg>
        </a>
        </div>
        </div>

        `)
        return content;
      } else {
        let content = $(`<div class="audio"><div class="frame-wrapper placeholder">
          <a href="${embed.url}" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM384 256L176 384V128L384 256z"/></svg>
        </a>
        </div>
        </div>

        `)
        return content;
      }

    }

  }
}

document.addEventListener("DOMContentLoaded", function () {
  'use strict';
  //=include ../../templates/components/**/*.js

  let totalHC = $('.homepage-carousel .homepage-carousel-item').length;
  let totalTC = $('.top-carousel .top-carousel-item').length;
  let randHC = Math.floor(Math.random() * totalHC);
  let randTC = Math.floor(Math.random() * totalTC);

  $('.homepage-carousel').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    initialSlide: randHC,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('.top-carousel').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    initialSlide: randTC
  });

  window.onAcceptCookie = function (cookie) {
    if ($.inArray('third_party', cookie.level) > -1) {
      $('.audio-video-cookies').remove();
      buildWidget();
    }
  };

  $('body').on('click', '.accept-third-party-cookies', function (e) {
    e.preventDefault();

    window.cookieConsent.accept('third_party');
    buildWidget();
  });

  $('body').on('click', '.decline-third-party-cookies', function (e) {
    e.preventDefault();

    window.cookieConsent.set('data', { value: { decline_third_party_cookies: true } });
    $('.cookie-bar').remove();
  });
});

$('body').on('click', '.js-connect-calendar', function (e) {
  e.preventDefault();
  e.stopPropagation();

  let copyText;

  if ($(this).is('li')) {
    copyText = $(this).find('a').attr('href');
  } else {
    copyText = $(this).attr('href');
  }

  document.addEventListener('copy', function (e) {
    e.clipboardData.setData('text/plain', copyText);
    e.preventDefault();
  }, true);
  document.execCommand('copy');
  $('.calendarModal').show();
})


$('body').on('click', '.js-close-calendar-modal', function (e) {
  e.preventDefault();
  e.stopPropagation();
  $('.calendarModal').hide();

})

$('body').on('click', '.js-close-newsletter-modal', function (e) {
  e.preventDefault();
  e.stopPropagation();
  $('.newsletterModal').hide();

})

window.addEventListener('load', buildWidget);



